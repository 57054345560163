/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

// primeng
@import "primeng.scss";

// Montserrat font
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

// Maven+Pro
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&family=Playwrite+GB+S:ital,wght@0,100..400;1,100..400&display=swap");

// :root {
//     font-family: "Montserrat", ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
// }
// for automatic time picker
ngx-mat-timepicker-field {
    .mat-mdc-form-field.mat-form-field-appearance-fill
        .mat-mdc-text-field-wrapper
        .mat-mdc-form-field-flex {
        // padding: 0 10px;
        padding: 0 6px;
    }
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

.newline-snackbar {
    white-space: pre-wrap;
}

.mat-mdc-paginator .mat-mdc-paginator-container {
    justify-content: space-between;
}

.timepicker-dial__control {
    width: 64px!important;
}