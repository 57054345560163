// primeng
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

p-calendar {
    --mat-mdc-form-field-floating-label-scale: 0.75;
    display: inline-flex;
    flex-direction: column;
    min-width: 0;
    text-align: left;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mat-form-field-container-text-font);
    line-height: var(--mat-form-field-container-text-line-height);
    font-size: var(--mat-form-field-container-text-size);
    letter-spacing: var(--mat-form-field-container-text-tracking);
    font-weight: var(--mat-form-field-container-text-weight);

    padding: 0;
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1 !important;
    border-color: rgb(203 213 225 / 1) !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / 1) !important;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;

    position: relative;
    display: flex;
    align-items: stretch;
    .p-calendar {
        position: static;
        display: flex;
        align-items: center;
        min-height: 48px;
        padding: 0;
        border: 0;
        .p-inputtext {
            font: inherit;
            background: rgba(0, 0, 0, 0);
            color: currentColor;
            border: none;
            outline: none;
            box-shadow: none;
            padding: 0 16px;
            margin: 0;
            vertical-align: bottom;
            text-align: inherit;
            -webkit-appearance: none;
            width: 100%;
            height: 100%;
        }
        .p-button:focus {
            box-shadow: none;
        }
    }
}

.p-datepicker table td > span {
    width: 1.5rem;
    height: 1.5rem;
}

.p-datepicker, .p-datepicker table {
    font-size: 14px;
}

.p-datepicker .p-timepicker span {
    font-size: 1rem;
}
